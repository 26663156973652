import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroVideo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center md:py-2 py-10">
        <video
          poster="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Video%2FCash%20Payment.mp4?alt=media&token=eef45e3c-8119-42b2-b822-c44565c88f8d"
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full h-[600px] object-cover"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Video%2FCash%20Payment.mp4?alt=media&token=eef45e3c-8119-42b2-b822-c44565c88f8d"
            type="video/mp4"
          />
        </video>
        <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute md:bottom-[180px] bottom-[-20px] md:text-start text-center text-white">
          <div className="md:w-[50%] py-4">
            <h1 className="md:text-start">{rpdata?.dbSlogan?.[1].slogan}</h1>
            <p className="md:text-start">{rpdata?.dbValues?.[0].description}</p>
            <ButtonContent btnStyle="three" />
          </div>
          <div className="md:w-[50%]">
            <img src={rpdata?.gallery?.[3]} alt="Img" className="mb-[-30px]"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroVideo;
